
import { defineComponent } from "vue";
import jsonData from "@/json/events.json";
import teachersData from "@/json/teachers.json";
import Button from "@/components/common-elements/Button.vue";
import { EventModel } from "@/models/event.model";
import { Metatags } from "@/scripts/metatags";

export default defineComponent({
  name: "Event",
  components: {
    Button,
  },
  created() {
    const temp = this.getEvent(this.itemData, "id", this.$route.params.id);
    if (!temp) {
      this.showError();
      return;
    }
    this.item = new EventModel().deserialize(
      this.getEvent(this.itemData, "id", this.$route.params.id)
    );
    Metatags.change({
      title: this.item.title,
      description: this.item.description,
      image: this.item.thumb,
    });
  },
  methods: {
    getEvent(
      array: Array<any>,
      keyType: string,
      keyValue: string | string[]
    ): EventModel {
      const arrayItem = array.filter((item: any) => {
        return item[keyType] === keyValue;
      })[0];
      if (!arrayItem) {
        return null;
      }
      return arrayItem;
    },
    getImgUrl(image: string) {
      var images = require.context("@/assets/events_img/", false, /\.jpg$/);
      return images("./" + image + ".jpg");
    },
    getTeacherImgUrl(id: string) {
      let newImage = this.getTeacher(id);
      var images = require.context("@/assets/teachers_img/", false, /\.png$/);
      return images("./" + newImage + ".png");
    },
    getTeacher(id: string) {
      const item = this.teacherData.filter((item: any) => {
        return item.id === id;
      })[0];
      if (!item) {
        return "profile_avatar";
      }
      return item.thumb;
    },
    showError() {
      this.notExist = true;
    },
    renderDate(date: string, pos = 0) {
      const dateExplode = date.split("-");
      return pos === 2
        ? dateExplode[2]
        : pos === 1
        ? this.months[Number(dateExplode[1]) - 1]
        : dateExplode[0];
    },
    convertDate(date: string, start = true) {
      const dateExplode = date.split("-");
      let newDate = new Date(
        Number(dateExplode[0]),
        Number(dateExplode[1]) - 1,
        Number(dateExplode[2]),
        0,
        0,
        0
      );
      if (!start) {
        newDate = new Date(
          Number(dateExplode[0]),
          Number(dateExplode[1]) - 1,
          Number(dateExplode[2]) + 1,
          23,
          59,
          59
        );
      }

      return newDate;
    },
  },
  data() {
    return {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      itemData: jsonData,
      teacherData: teachersData,
      notExist: false,
      teachers: [],
      item: new EventModel(),
      planMyAcroText:
        "<p>Check more Acro Events at <a href='https://planmyacro.site' >https://planmyacro.site</a> <br/>Presented to you by PlanMyAcro Team | 3NJ0Y</p>",
    };
  },
});
