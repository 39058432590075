import { AbstractModel } from "./abstract.model";
export class EventModel extends AbstractModel {
  id: string;
  title: string;
  thumb: string;
  categories: Array<any>;
  description: string;
  content: string;
  price: unknown;
  localization: unknown;
  link: string;
  signup_link: string;
  teachers: Array<any>;

  public deserialize(obj: any): this {
    Object.assign(this, obj);
    return this;
  }
}
