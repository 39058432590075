
import { defineComponent } from "vue";
import jsonData from "@/json/teachers.json";
import Button from "@/components/common-elements/Button.vue";

export default defineComponent({
  name: "Event",
  components: {
    Button,
  },
  created() {
    this.element = this.getElement(this.element, "id", this.$route.params.id);
    let test = this.$route.query.referenceId
      ? this.$route.query.referenceId
      : "";
    this.referenceId = test.toString();
    if (!this.element) {
      this.showError();
    }
  },
  methods: {
    getElement(
      array: Array<any>,
      keyType: string,
      keyValue: string | string[]
    ) {
      const arrayElement = array.filter((element: any) => {
        return element[keyType] === keyValue;
      })[0];
      if (!arrayElement) {
        return false;
      }
      return arrayElement;
    },
    getImgUrl(image: string) {
      var images = require.context("@/assets/teachers_img/", false, /\.png$/);
      return images("./" + image + ".png");
    },
    showError() {
      this.notExist = true;
    },
  },
  data() {
    return {
      element: jsonData,
      notExist: false,
      teachers: [],
      referenceId: "",
    };
  },
});
